<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-stepper id="iiotDeployFormStepper" v-model="step" v-resize="onResize" class="d-flex flex-column mt-6 ml-6 mr-6">
    <v-stepper-items class="flex-grow-1">
      <v-stepper-content step="1" class="pa-0">
        <v-col class="flex-shrink-1 flex-grow-0 title mb-13 pa-0">
          <h1>
            {{ dryRun ? $t('deployForm.workloadStep1.dryRun') : $t('deployForm.workloadStep1.title') }}
          </h1>
          <v-divider class="mb-0" />
        </v-col>
        <deploy-step-one @selected-version="eventSelectedVersion" />
      </v-stepper-content>
      <v-stepper-content step="2" class="pa-0">
        <v-col class="flex-shrink-1 flex-grow-0 title mb-13 pa-0">
          <h1>
            {{ dryRun ? $t('deployForm.workloadStep2.dryRun') : $t('deployForm.workloadStep2.title') }}
          </h1>
          <v-divider class="mb-0" />
        </v-col>
        <deploy-step-two v-if="step === 2" :workload="selectedWorkload" />
      </v-stepper-content>

      <v-stepper-content step="3" class="pa-0">
        <v-col class="flex-shrink-1 flex-grow-0 title mb-13 pa-0">
          <h1>
            {{ dryRun ? $t('deployForm.workloadStep3.dryRun') : $t('deployForm.workloadStep3.title') }}
          </h1>
          <v-divider class="mb-0" />
        </v-col>
        <deploy-step-three
          :version-name="generalInfo.versionName"
          :workload-name="generalInfo.wlName"
          :selected-workload-type="generalInfo.selectedWorkloadType"
          :selected-nodes="selectedNodes.length"
          :deploy-name="deployName"
          @deploy-name="eventDeployNameChanged"
          @deploy="eventDeploy"
        />
      </v-stepper-content>
    </v-stepper-items>
    <div class="stepper-and-buttons">
      <v-row v-if="!isMarginVisible" class="pb-4 pl-3 pr-3">
        <v-col cols="3" class="d-flex justify-start align-center pa-0" :style="btnStyle">
          <nerve-button
            v-if="step > 1"
            id="iiotDeployFormCancelButton"
            :text="$t('deployForm.backBtn')"
            type-of-btn="cancel"
            size="normal"
            class="mt-0 mb-1"
            data-cy="iiotDeployFormCancelButton"
            @click-event="back()"
          />
        </v-col>
        <v-col cols="6" class="d-flex justify-center align-center pa-0">
          <v-stepper-header>
            <v-stepper-step
              class="pa-0"
              :class="{
                'v-stepper__step--active': step === 1,
                'v-stepper__step--inactive': step !== 1,
              }"
              step="1"
            />
            <v-divider />
            <v-stepper-step
              class="pa-0"
              :class="{
                'v-stepper__step--active': step === 2,
                'v-stepper__step--inactive': step !== 2,
              }"
              step="2"
            />
            <v-divider />
            <v-stepper-step
              class="pa-0"
              :class="{
                'v-stepper__step--active': step === 3,
                'v-stepper__step--inactive': step !== 3,
              }"
              step="3"
            />
          </v-stepper-header>
        </v-col>
        <v-col cols="3" class="d-flex justify-end align-center pa-0">
          <nerve-button
            id="iiotDeployFormCreateDeployButton"
            :text="step === 3 ? $t('deployForm.executeStep.deployBtn') : $t('deployForm.nextBtn')"
            :style="btnStyle"
            :disabled="!canAccess('UI_DEPLOY:DEPLOY') || isBtnDisabled()"
            class="ma-0"
            type-of-btn="action"
            size="normal"
            data-cy="iiotDeployFormCreateDeployButton"
            @click-event="step === 3 ? deploy() : next()"
          />
        </v-col>
      </v-row>
      <v-row v-else class="pl-3 pr-3">
        <v-col cols="6" class="d-flex justify-start align-center pa-0 pt-4">
          <nerve-button
            v-if="step > 1"
            id="iiotDeployFormCancelButton"
            :text="$t('deployForm.backBtn')"
            type-of-btn="cancel"
            size="normal"
            :style="btnStyle"
            class="ma-0"
            data-cy="iiotDeployFormCancelButton"
            @click-event="back()"
          />
        </v-col>

        <v-col cols="6" class="d-flex justify-end align-center pa-0 pt-4">
          <nerve-button
            id="iiotDeployFormCreateDeployButton"
            :text="step === 3 ? $t('deployForm.executeStep.deployBtn') : $t('deployForm.nextBtn')"
            :style="btnStyle"
            :disabled="!canAccess('UI_DEPLOY:DEPLOY') || isBtnDisabled()"
            type-of-btn="action"
            size="normal"
            class="ma-0"
            data-cy="iiotDeployFormCreateDeployButton"
            @click-event="step === 3 ? deploy() : next()"
          />
        </v-col>
        <v-col cols="12" class="d-flex justify-center align-center pa-0 pb-4">
          <v-stepper-header>
            <v-stepper-step
              class="pa-0"
              :class="{
                'v-stepper__step--active': step === 1,
                'v-stepper__step--inactive': step !== 1,
              }"
              step="1"
            />
            <v-divider class="step-divider" />
            <v-stepper-step
              class="pa-0"
              :class="{
                'v-stepper__step--active': step === 2,
                'v-stepper__step--inactive': step !== 2,
              }"
              step="2"
            />
            <v-divider class="step-divider" />
            <v-stepper-step
              class="pa-0"
              :class="{
                'v-stepper__step--active': step === 3,
                'v-stepper__step--inactive': step !== 3,
              }"
              step="3"
            />
          </v-stepper-header>
        </v-col>
      </v-row>
    </div>
  </v-stepper>
</template>

<script>
import { NerveButton } from 'nerve-ui-components';
import DeployStepOne from '@/components/deploy/deploy-helpers/DeployStepOne.vue';
import DeployStepTwo from '@/components/deploy/deploy-helpers/DeployStepTwo.vue';
import DeployStepThree from '@/components/deploy/deploy-helpers/DeployStepThree.vue';
import shared from '../../helpers/shared';
import Logger from '@/utils/logger';

export default {
  components: {
    DeployStepThree,
    DeployStepTwo,
    DeployStepOne,
    NerveButton,
  },
  data() {
    return {
      step: 1,
      formValid: false,
      deployInformation: {},
      backAction: false,
      generalInfo: {},
      deployName: new Date().toLocaleString('en-GB'),
      selectedWorkload: {},
      dryRun: false,
      isMarginVisible: false,
      width: 190,
      origin: 'from-deployment',
      isDisabled: false,
      workload_type: '',
    };
  },
  computed: {
    selectedNodes() {
      return this.$store.getters['nodes/selectedNodesList'];
    },
    btnStyle() {
      return {
        '--width': `${this.width}px`,
      };
    },
    workload() {
      if (this.selectedWorkload.type === 'docker-compose') {
        return this.$store.getters['workloads/getComposeWorkload'];
      }
      return this.$store.getters['workloads/getWorkload'];
    },
  },
  async mounted() {
    this.dryRun = window.location.pathname.split('/')[2] === 'dryRun';
    this.deployInformation.dryRun = this.dryRun;
    if (window.location.pathname.includes('workload/')) {
      this.step = 2;
      const route = window.location.pathname.split('/');
      this.origin = route[route.length - 1];

      if (this.dryRun && this.origin !== 'from-deployment') {
        this.$router.push({ name: 'Deploy Form Dry Run' }).catch(() => {});
      }
      if (!this.dryRun && this.origin !== 'from-deployment' && this.origin !== 'from-workload') {
        this.$router.push({ name: 'Deploy Form' }).catch(() => {});
      }
      this.selectedWorkload.workloadId = route[route.length - 3];
      this.selectedWorkload.versionId = route[route.length - 2];
      this.selectedWorkload.type = route[route.length - 4];
      this.deployInformation.workloadId = this.selectedWorkload.workloadId;
      this.deployInformation.versionId = this.selectedWorkload.versionId;
      try {
        if (this.selectedWorkload.type === 'docker-compose') {
          await this.$store.dispatch('workloads/get_compose_workload_by_id', this.selectedWorkload.workloadId);
        } else {
          await this.$store.dispatch('workloads/get_workload_by_id', this.selectedWorkload.workloadId);
        }
      } catch {
        this.$router.push({ name: 'Deploy Form' }).catch(() => {});
      }
      const version = this.workload.versions.find((v) => v._id === this.selectedWorkload.versionId);
      if (!version) {
        this.$router.push({ name: 'Deploy Form' }).catch(() => {});
      }
      this.generalInfo.selectedWorkloadType = this.workload.type;
      this.generalInfo.wlName = this.workload.name;
      this.generalInfo.versionName = version.name;
    }
    this.deployInformation.retryTimes = 3;
  },
  destroyed() {
    this.$store.dispatch('nodes/deselect_all_nodes');
  },
  methods: {
    isBtnDisabled() {
      return (
        (this.step === 1 && !this.deployInformation.versionId) ||
        (this.step === 2 && !this.selectedNodes.length) ||
        (this.step === 3 && !this.formValid) ||
        this.isDisabled
      );
    },
    eventSelectedVersion(e) {
      this.selectedWorkload = e.deployInfo;
      this.deployInformation = Object.assign(this.deployInformation, e.deployInfo);
      this.generalInfo = e.generalInfo;
    },
    eventDeployNameChanged(e) {
      this.deployName = e.deployName;
      this.formValid = e.formValid;
    },
    eventDeploy(e) {
      this.formValid = e.formValid;
      this.deploy();
    },
    back() {
      if (this.step === 3 && this.deployInformation.deployName === undefined) {
        this.deployInformation.deployName = this.deployName;
      }
      if (this.step === 2 && this.origin !== 'from-deployment' && !this.dryRun) {
        if (this.canAccess('UI_WORKLOAD:VIEW')) {
          this.$router
            .push({
              name: 'Add edit workload',
              params: {
                id: this.selectedWorkload.workloadId,
                type: this.generalInfo.selectedWorkloadType,
              },
            })
            .catch(() => {});
          return;
        }
        this.$router.push({ name: 'Deploy Form' }).catch(() => {});
        return;
      }
      if (this.step === 2 && this.origin === 'from-deployment' && !this.dryRun) {
        this.$router.push({ name: 'Deploy Form' }).catch(() => {});
      }
      if (this.step === 2 && this.dryRun) {
        this.$router.push({ name: 'Deploy Form Dry Run' }).catch(() => {});
      }
      // eslint-disable-next-line no-plusplus
      this.step--;
    },
    async next() {
      if (this.step === 1 && this.selectedWorkload && this.dryRun) {
        this.$router
          .push({
            name: 'Deploy Form Step Two Dry Run',
            params: {
              type: this.selectedWorkload.type || this.generalInfo.selectedWorkloadType,
              workloadId: this.selectedWorkload.workloadId,
              versionId: this.selectedWorkload.versionId,
              origin: this.origin,
            },
          })
          .catch(() => {});
      }
      if (this.step === 1 && this.selectedWorkload && !this.dryRun) {
        this.$router
          .push({
            name: 'Deploy Form Step Two',
            params: {
              type: this.selectedWorkload.type || this.generalInfo.selectedWorkloadType,
              workloadId: this.selectedWorkload.workloadId,
              versionId: this.selectedWorkload.versionId,
              origin: this.origin,
            },
          })
          .catch(() => {});
      }
      if (this.step === 2 && this.deployInformation.deployName === this.deployName) {
        this.deployName = new Date().toLocaleString('en-GB');
      }
      // eslint-disable-next-line no-plusplus
      this.step++;
    },
    async deploy() {
      if (this.formValid) {
        this.deployInformation.deployName = this.deployName;
        this.deployInformation.nodes = this.selectedNodes.map((node) => node.serialNumber);
        // eslint-disable-next-line max-len
        const selectedWorkloadVersion = this.workload?.versions?.find(
          (version) => version._id === this.deployInformation.versionId,
        );
        if (this.workload.type === 'docker-compose' && selectedWorkloadVersion.numberOfServices > 1) {
          // In case that workload is docker-compose and has more than two available
          // files(compose and image) and selected nodes are older than 2.7.0 deployment
          // should not be sent on the backend and error message is returned
          const oldNodes = this.selectedNodes.filter(
            (node) => !shared.isNoPrerelesedVersionGreaterThanOrEqualTo(node.currentFWVersion, '2.7.0'),
          );
          if (oldNodes && oldNodes.length !== 0) {
            const oldNodesSerialNumbers = oldNodes.map((node) => node.serialNumber);
            this.$store.dispatch('utils/_api_request_handler/show_deploy_info_dialog', {
              title: 'deployForm.confirmDialogTitle',
              serialNumbers: oldNodesSerialNumbers.join(', '),
              subTitle: 'deployForm.confirmDialogText',
              callback: async () => {
                // eslint-disable-next-line no-plusplus
                this.step = 2;
              },
            });
            return;
          }
        }
        await this.deployWorkload();
      }
    },
    async deployWorkload() {
      try {
        await this.$store.dispatch('workloads/deploy', this.deployInformation);
        const newDeployName = this.$store.getters['workloads/getNewDeployName'];
        if (this.deployName !== newDeployName) {
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: this.$t('deployForm.duplicateNameOfDeploy', {
              oldDeployName: this.deployName,
              newDeployName,
            }),
            color: 'primary',
            showClose: true,
          });
          this.deployName = newDeployName;
          this.deployInformation.deployName = this.deployName;
          this.isDisabled = true;
          setTimeout(() => {
            this.$router.push({ name: 'Deploy Log List' });
          }, 1200);
          return;
        }
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'deployForm.successDeploy',
          color: 'primary',
          showClose: true,
        });
        this.isDisabled = true;
        setTimeout(() => {
          this.$router.push({ name: 'Deploy Log List' });
        }, 1200);
      } catch (e) {
        Logger.error(e);
      }
    },
    onResize() {
      this.isMarginVisible = window.innerWidth < 750;
      if (window.innerWidth > 600) {
        this.width = 190;
        return;
      }
      if (window.innerWidth <= 600 && window.innerWidth > 420) {
        this.width = 170;
        return;
      }
      if (window.innerWidth <= 420 && window.innerWidth > 400) {
        this.width = 150;
        return;
      }
      if (window.innerWidth <= 400 && window.innerWidth > 370) {
        this.width = 130;
        return;
      }
      this.width = 110;
    },
  },
};
</script>

<style lang="scss">
#iiotDeployFormStepper {
  min-height: 90vh;
  &.v-stepper,
  .v-stepper__wrapper,
  .v-stepper__content,
  .flex-grow-1 > .row {
    box-shadow: none;
  }
  .v-stepper__header {
    box-shadow: none;
    max-width: 350px;
  }

  .stepper-and-buttons {
    .v-stepper__step--active {
      span {
        color: #fff !important;
        background-color: var(--v-primary-base) !important;
        font-size: 20px;
      }
    }
    .v-stepper__step {
      span {
        color: var(--v-stepperColor-base);
        background-color: var(--v-stepperBackground-base);
        font-size: 20px;
      }
    }
    .v-stepper__step__step {
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 0;
    }
    .v-divider {
      width: 30px;
      margin: 0;
    }
  }
  .next-button {
    height: 40px;
    width: var(--width);
  }
  .workloadImg {
    width: 100%;
    height: 90px;
    border-radius: 5px;
    font-size: 20px;
    color: white;
    white-space: normal;
    &:focus,
    &.box-shadow {
      box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.25);
      outline: none;
    }

    .v-icon {
      opacity: 0.3;
      position: absolute;
      left: 30px;
    }
  }
  .back-btn {
    width: var(--width);
  }
  .node-info {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
}
</style>
